import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { HeadFC, Link } from "gatsby"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import GetThePrices from "../../components/gettheprices/gettheprices"
import NewsletterSignup from "../../components/newslettersignup/newslettersignup"
import "./blog.scss"
import strings from "../../i18n"

export default function Template({
  location,
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const authorImgFluid = frontmatter.authorImage.childImageSharp.fluid
  const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
  const tags = frontmatter.tags
  const url = "https://homefounder.com" + location.pathname
  const facebookURL = "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(url)
  const linkedIn = "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(url)
  const twitterURL = "https://twitter.com/intent/tweet?url="+ encodeURIComponent(url) +"&text=" +encodeURIComponent(frontmatter.title)
  var tagElems = []
  for (var i in tags){
    const tag = tags[i]
    tagElems.push(<div className="tag body2">{tag}</div>)
  }
  return (
    <Layout chat={true}>
      <div className="blog section">
        <div className="container">
      <div className="blog-post-container">
        <div className="blog-post">
          <Link to="/blog" className="h4 homefounder-blog">{strings.homefounder_blog}</Link>
          <h2>{frontmatter.title}</h2>
          <div className="body2 byline">
            <div className="info"><Img fluid={authorImgFluid} />{frontmatter.author}<div className="sep"></div>{frontmatter.date}{tagElems}</div>
            <div className="share"><a href={facebookURL} target="_blank" className="facebook"></a><a href={twitterURL} target="_blank" className="twitter"></a><a href={linkedIn} target="_blank" className="linkedin"></a></div>
          </div>
          <div className="blog-post-content">
          <Img fluid={featuredImgFluid} />
          <div
            className="body1"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className="byline">
            <div className="info"><Img fluid={authorImgFluid} /><div className="author"><h3 className="name">{frontmatter.author}</h3><p className="title">{frontmatter.authorTitle}</p></div></div>
            <div className="share"><a href={facebookURL} target="_blank" className="facebook"></a><a href={twitterURL} target="_blank" className="twitter"></a><a href={linkedIn} target="_blank" className="linkedin"></a></div>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    <NewsletterSignup/>
    <GetThePrices/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        authorTitle
        description
        tags
        authorImage {
          childImageSharp {
            fluid(maxWidth: 96) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1088) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }`

  export const Head = ({ location, params, data, pageContext }) => {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid.src
    const url = "https://homefounder.com" + location.pathname
    return (
    <>
      <title>{frontmatter.title}</title>
      <meta name="description" content={frontmatter.description} />
      <meta name="image" content={featuredImgFluid} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={frontmatter.title} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:description" content={frontmatter.description} />
      <meta name="twitter:image" content={frontmatter.image} />
      <meta property="og:title" content={frontmatter.title} />
      <meta property="og:description" content={frontmatter.description} />
      <meta property="og:image" content={featuredImgFluid} />
      <meta property="og:url" content={url} />
    </>
  )
  }